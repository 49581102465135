import React, { useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { FoodBoxTileContainer } from './styles';
import { useIntl } from 'react-intl';
import FoodBoxOutline from 'Assets/img/food-box-outline.png';
import { FoodBoxModalModuleStore } from '../Home/Modals/FoodBoxModal/FoodBoxModalModuleStore';
import { FoodBoxModuleStore } from './FoodBoxModuleStore';
import { FoodBoxStatus } from './enums/FoodBoxStatus';

const FoodBoxTile = () => {
  const intl = useIntl();
  const modalStore = FoodBoxModalModuleStore.Instance().Use();
  const foodBoxModuleStore = FoodBoxModuleStore.Instance().Use();

  useEffect(() => {
    foodBoxModuleStore.HydrateFoodBoxProgress();
  }, []);

  const foodBoxStatus = foodBoxModuleStore.FoodBoxStatus;
  const statusesToShowTile = [FoodBoxStatus.ENROLLED, FoodBoxStatus.AWAITINGDELIVERY];
  if (!statusesToShowTile.includes(foodBoxStatus)) {
    return <></>;
  }

  return (
    <Grid item mobile={12} tablet={6}>
      <FoodBoxTileContainer>
        {foodBoxStatus === FoodBoxStatus.ENROLLED ? (
          <>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item mobile={2}>
                <img src={FoodBoxOutline} alt="Food Box" />
              </Grid>
              <Grid item mobile={10} textAlign="left">
                <Typography>You are one step closer!</Typography>
                <Typography fontWeight={700}>Just a couple more steps!</Typography>
              </Grid>
            </Grid>
            <Button
              onClick={() => {
                modalStore.GoToScreeners();
              }}
              variant="contained"
              sx={{ backgroundColor: '#6C0BA9', color: '#FFFFFF', width: '50%', marginTop: '16px' }}
            >
              <Typography>{intl.formatMessage({ id: 'common.lets-go' })}</Typography>
            </Button>
          </>
        ) : (
          <Typography>
            You are all set! Your food box should be delivered within the next 1-2 weeks. If you have any questions
            please tap on &ldquo;Talk to Someone&rdquo; below.
          </Typography>
        )}
      </FoodBoxTileContainer>
    </Grid>
  );
};

export default FoodBoxTile;
