import AuthorizationService from 'Core_Helpers/authentication/AuthService';
import StorageService from 'Core_Helpers/authentication/StorageService';
import { default as OnboardStorageService } from 'Onboard_Helpers/authentication/StorageService';
import { default as OnboardAuthorizationService } from 'Onboard_Helpers/authentication/AuthService';
import { copyTokensFromLocalStorage } from 'Core_Redux/authentication';
import { AppExperienceService } from './AppExperienceService';
import { ExperienceStore } from '../stores/experienceStore';

export class AppInitializationOrchestrator {
  coreStore;
  onboardStore;
  experienceService;

  constructor(coreStoreInstance, onboardStoreInstance) {
    this.coreStore = coreStoreInstance;
    this.onboardStore = onboardStoreInstance;
    this.experienceService = new AppExperienceService();
  }
  async orchestrateCoreExperienceInitAsync() {
    await StorageService.getInstance();
    await AuthorizationService.getInstance(this.coreStore.store);
    await this.coreStore.store.dispatch(copyTokensFromLocalStorage()).unwrap();
  }

  async orchestrateOnboardExperienceInitAsync() {
    await OnboardStorageService.getInstance();
    await OnboardAuthorizationService.getInstance(this.onboardStore.store);
  }

  async orchestrateUserExperienceInitAsync() {
    let initialUserExperience;
    const experienceStore = new ExperienceStore();
    const coreState = this.coreStore.store.getState();

    if (coreState?.user?.syncUser) {
      initialUserExperience = await this.experienceService.retrieveInitExperienceAsync(coreState.user.syncUser);
    } else {
      initialUserExperience = await this.experienceService.hydrateAndRetrieveInitialExperienceAsync();
    }

    await experienceStore.updateExperience(initialUserExperience);
  }
}
