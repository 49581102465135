import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { UserAccessor } from 'Core_Helpers/UserAccessor';
import { FoodBoxApiService } from './services/FoodBoxApiService';
import { FoodBoxStatus } from './enums/FoodBoxStatus';

export class FoodBoxModuleStore extends Singleton {
  private userAccessor: UserAccessor;
  private foodBoxApiService: FoodBoxApiService;
  private atomStore = getDefaultStore();
  private foodBoxStatusAtom;
  private errorAtom;
  private isLoadingAtom;

  constructor() {
    super();
    this.userAccessor = new UserAccessor();
    this.foodBoxApiService = new FoodBoxApiService();
    this.foodBoxStatusAtom = atom(FoodBoxStatus.NOTENROLLED);
    this.errorAtom = atom(null);
    this.isLoadingAtom = atom(false);

    this.HydrateFoodBoxProgress();
  }

  public Use = () => {
    useAtom(this.foodBoxStatusAtom);
    useAtom(this.errorAtom);
    useAtom(this.isLoadingAtom);
    return this;
  };

  public get FoodBoxStatus() {
    return this.atomStore.get(this.foodBoxStatusAtom);
  }

  public get IsLoading() {
    return this.atomStore.get(this.isLoadingAtom);
  }

  public get Error() {
    return this.atomStore.get(this.errorAtom);
  }

  public HydrateFoodBoxProgress = async () => {
    this.atomStore.set(this.isLoadingAtom, true);
    const { response, hasError } = await this.foodBoxApiService.getFoodBoxProgress(this.userAccessor.User.UserId);

    if (hasError) {
      this.atomStore.set(this.errorAtom, true);
      this.atomStore.set(this.isLoadingAtom, false);
      return;
    }

    this.atomStore.set(this.foodBoxStatusAtom, response);
    this.atomStore.set(this.isLoadingAtom, false);
  };
}
