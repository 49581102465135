import axios from 'axios';
import 'regenerator-runtime/runtime.js';
import { isEnvironment, PRODUCTION } from 'Core_Helpers/isEnvironment';
import { appInsights } from 'Core_Helpers/AppInsights';
import StorageService from 'Core_Helpers/authentication/StorageService';

const getUnauthenticatedApiClient = async () => {
  const authorizedApi = axios.create();

  authorizedApi.interceptors.request.use((request) => {
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    return request;
  });

  authorizedApi.interceptors.response.use(
    (response) => {
      response.responseTime = new Date().getTime() - response.config.meta.requestStartedAt;
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        appInsights.trackTrace({ message: 'User not authenticated.' });
      } else {
        return Promise.reject(error);
      }
    },
  );

  try {
    const storage = await StorageService.getInstance();
    const dateTimeOverride = await storage.getItem('dateTimeOverride');
    if (dateTimeOverride && !isEnvironment(PRODUCTION)) {
      authorizedApi.defaults.headers.common['x-datetime-override'] = dateTimeOverride;
    }
    return authorizedApi;
  } catch (error) {
    appInsights.trackException({
      exception: `Exception: Failed to get dateTimeOverride from localStorage.`,
      properties: { errorMessage: error?.message },
    });
  }
};

export default getUnauthenticatedApiClient;
