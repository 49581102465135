import React from 'react';
import { LinkOnboardModuleStore } from './LinkOnboardModuleStore';

const LinkOnboard = () => {
  const linkOnboardModuleStore = LinkOnboardModuleStore.Instance().Use();
  const Component = linkOnboardModuleStore.CurrentPage.component;

  return <Component />;
};

export default LinkOnboard;
