import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { ContentContainer, StyledDialog } from './styles';
import Market from 'Assets/img/market.png';
import Button from 'Stories/components/Button';
import { FoodBoxModalModuleStore } from './FoodBoxModalModuleStore';

function FoodBoxModal() {
  const intl = useIntl();
  const modalStore = FoodBoxModalModuleStore.Instance().Use();
  const isOpen = modalStore.IsOpen;

  const onClick = () => {
    modalStore.GoToScreeners();
  };

  return (
    <>
      <StyledDialog open={isOpen}>
        <ContentContainer>
          <Typography fontWeight="bold" fontSize="20px" sx={{ paddingTop: '16px' }}>
            You Are One Step Closer!
          </Typography>
          <Typography fontSize="20px" sx={{ paddingTop: '16px' }}>
            Just a couple more things and you will be all set with a free FoodBox!
          </Typography>
          <img
            src={Market}
            alt={intl.formatMessage({ id: 'image.pyxir.portrait' })}
            style={{ width: '70%', paddingTop: '24px', paddingBottom: '24px' }}
          />
          <Button onClick={onClick} sx={{ backgroundColor: '#FF08AB', maxWidth: '240px' }}>
            <Typography color="white">{intl.formatMessage({ id: 'common.lets-go' })}</Typography>
          </Button>
        </ContentContainer>
      </StyledDialog>
    </>
  );
}

export default FoodBoxModal;
