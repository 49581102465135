import React from 'react';
import { CardContainer, StepsText, Title } from './styles';
import NumberedCircle from './components/NumberedCircle';
import { Grid } from '@mui/material';

const Card2 = () => {
  return (
    <CardContainer>
      <Title>How we help you Thrive!</Title>
      <Grid container spacing={2} sx={{ paddingTop: '32px' }}>
        <Grid item mobile={2}>
          <NumberedCircle number={1} />
        </Grid>
        <Grid item mobile={10}>
          <StepsText>Learn steps you can take and where to get started to improve your health care</StepsText>
        </Grid>
        <Grid item mobile={2}>
          <NumberedCircle number={2} />
        </Grid>
        <Grid item mobile={10}>
          <StepsText>Personalized Care Path designed for you and your needs</StepsText>
        </Grid>
        <Grid item mobile={2}>
          <NumberedCircle number={3} />
        </Grid>
        <Grid item mobile={10}>
          <StepsText>
            Designated Peer who has walked in your shoes and is there with you every step of the way
          </StepsText>
        </Grid>
        <Grid item mobile={2}>
          <NumberedCircle number={4} />
        </Grid>
        <Grid item mobile={10}>
          <StepsText>24/7 Call Center, answering your questions when you need them.</StepsText>
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default Card2;
