import { createSlice, createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { appInsights } from 'Onboard_Helpers/AppInsights';
import getAuthClient from 'Onboard_Helpers/getAuthClient';
import urlcat from 'urlcat';
import { confirmedUserUpdated, logout } from './authentication';
import identityApi from './services/identity';
import api from './services/api';

export const patchSyncUser = createAsyncThunk('user/patchSyncUser', async ({ userId, patchObject }) => {
  const authorizedApiClient = await getAuthClient();
  const syncUrl = urlcat(process.env.API_BASE_URL, `/v5/members/${userId}`);
  if (authorizedApiClient) {
    await authorizedApiClient.patch(syncUrl, { memberPatchItems: patchObject });
    return patchObject;
  }
});

export const callUpdateSyncUser = createAsyncThunk('user/updateSyncUser', async ({ userId, patchObject }, thunkApi) => {
  await thunkApi.dispatch(api.endpoints.updateSyncUser.initiate({ userId, patchObject }));
});

export const userOnboarded = createAsyncThunk('user/userOnboarded', async (_, thunkApi) => {
  const { authentication, signup } = thunkApi.getState();
  await thunkApi.dispatch(identityApi.endpoints.refreshToken.initiate({ refreshToken: authentication.refreshToken }));
  await thunkApi.dispatch(api.endpoints.getAgreements.initiate(signup.userId));
});

const initialState = {
  syncUser: null,
  userInfo: null,
  language: 'en',
  userVerified: null,
  syncUserPending: false,
  tokenRefreshed: false,
  userMeta: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload.language;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patchSyncUser.rejected, (state, action) => {
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(identityApi.endpoints.userInfo.matchFulfilled, (state, action) => {
      state.userInfo = action.payload;
      state.userVerified =
        state.userInfo?.phone_number_verified == 'True' ||
        state.userInfo?.email_verified == 'True' ||
        state.userInfo?.is_force_confirmed == 'True';
    });
    builder.addMatcher(api.endpoints.synchronizeUser.matchPending, (state) => {
      state.syncUserPending = true;
    });
    builder.addMatcher(api.endpoints.synchronizeUser.matchFulfilled, (state, action) => {
      state.language = action.payload.language;
      state.syncUser = action.payload;
      state.syncUserPending = false;
    });
    builder.addMatcher(api.endpoints.getUserMeta.matchFulfilled, (state, action) => {
      state.userMeta = action.payload;
    });
    builder.addMatcher(isFulfilled(confirmedUserUpdated), (state) => {
      state.tokenRefreshed = true;
    });
    builder.addMatcher(isPending(callUpdateSyncUser), (state) => {
      state.syncUserPending = true;
    });
    builder.addMatcher(isFulfilled(callUpdateSyncUser), (state) => {
      state.syncUserPending = false;
    });
    builder.addMatcher(api.endpoints.synchronizeUser.matchRejected, (state, action) => {
      state.syncUserPending = false;
      appInsights.trackException({ exception: action.error });
    });
    builder.addMatcher(isFulfilled(logout), (state) => ({ ...initialState, language: state.language }));
  },
});

export const { changeLanguage } = userSlice.actions;

export const selectUserInfo = (state) => state.user.userInfo;
export const selectSyncUser = (state) => state.user.syncUser;
export const selectSyncUserPending = (state) => state.user.syncUserPending;
export const selectLanguage = (state) => state.user.language;
export const selectUserVerified = (state) => state.user.userVerified;
export const selectUserMeta = (state) => state.user.userMeta;

export default userSlice.reducer;
