import axios from 'axios';
import getUnauthenticatedApiClient from './getUnauthenticatedApiClient';
import urlcat from 'urlcat';

type ApiResponse = {
  response: any;
  hasError: boolean;
  error: object;
  success: boolean;
};

export class UnauthenticatedIdentityApiService {
  /** Creates a single instance of the class */
  private static _instance: UnauthenticatedIdentityApiService = new UnauthenticatedIdentityApiService();
  /** Documentation on abort controller usage: https://axios-http.com/docs/cancellation  */
  private static _abortController: AbortController = new AbortController();

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (UnauthenticatedIdentityApiService._instance) {
      return UnauthenticatedIdentityApiService._instance;
    }
  }

  public get = async (url: string, params: object): Promise<ApiResponse> => {
    const apiClient = await getUnauthenticatedApiClient();

    if (apiClient && url) {
      return await apiClient
        .get(urlcat(process.env.AUTHORITY as string, url, params), {
          signal: UnauthenticatedIdentityApiService._abortController.signal,
        })
        .then(function (response) {
          return {
            response: response?.data,
            hasError: false,
            error: undefined,
            success: true,
          };
        })
        .catch(function (error) {
          if (!axios.isCancel(error)) {
            return {
              response: undefined,
              hasError: true,
              error: error?.data,
              success: false,
            };
          }
        });
    }
  };

  public post = async (url: string, params: object, requestBody: object, headers: object): Promise<ApiResponse> => {
    const apiClient = await getUnauthenticatedApiClient();

    if (apiClient && url) {
      return await apiClient
        .post(urlcat(process.env.AUTHORITY as string, url, params), requestBody, {
          signal: UnauthenticatedIdentityApiService._abortController.signal,
          headers: headers,
        })
        .then(function (response) {
          return {
            response: response?.data,
            hasError: false,
            error: undefined,
            success: true,
          };
        })
        .catch(function (error) {
          if (!axios.isCancel(error)) {
            return {
              response: undefined,
              hasError: true,
              error: error?.response?.data,
              success: false,
            };
          }
        });
    }
  };

  public put = async (url: string, params: object, requestBody: object): Promise<ApiResponse> => {
    const apiClient = await getUnauthenticatedApiClient();

    if (apiClient && url) {
      return await apiClient
        .put(urlcat(process.env.AUTHORITY as string, url, params), requestBody, {
          signal: UnauthenticatedIdentityApiService._abortController.signal,
        })
        .then(function (response) {
          return {
            response: response?.data,
            hasError: false,
            error: undefined,
            success: true,
          };
        })
        .catch(function (error) {
          if (!axios.isCancel(error)) {
            return {
              response: undefined,
              hasError: true,
              error: error?.data,
              success: false,
            };
          }
        });
    }
  };
}
