import { Button } from '@mui/material';
import styled from 'styled-components';

export const PageContainer = styled.div`
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  padding: 24px;
  padding-top: 32px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PyxHealthHeader = styled.div`
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const Title = styled.div`
  opacity: 1;
  color: rgba(41, 40, 40, 1);
  font-size: 24px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: -0.24px;
  text-align: center;
  padding: 16px;
`;

export const ABitMoreInfoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

export const ABitMoreInfoChatBoxContainer = styled.div`
  position: relative;
  min-width: 200px;
`;

export const ABitMoreInfoText = styled.div`
  position: absolute;
  top: 20%;
  left: 10%;
  right: 0;
  bottom: 0;
  padding-right: 16px;
  color: #292828;
  font-size: 14px;
`;

export const FormContainer = styled.div`
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(238, 238, 238, 1);
  color: #514f4f;
  font-size: 14px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: -0.14px;
  padding: 16px;
  display: inline-grid;
  width: 100%;
`;

export const StyledNextButton = styled(Button)`
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(36, 147, 184, 1);
  color: #ffffff;
  margin: 8% 20% 0% 20%;
`;
