import React from 'react';
import WhatsNew from './WhatsNew';
import HolidayContentModal from './HolidayContent/HolidayContentModal';
import UpdateAppModal from 'Core_Pages/Home/Modals/UpdateAppModal/UpdateAppModal';
import FoodBoxModal from './FoodBoxModal/FoodBoxModal';

const Modals = () => {
  return (
    <>
      <UpdateAppModal />
      <WhatsNew />
      <HolidayContentModal />
      <FoodBoxModal />
    </>
  );
};

export default Modals;
