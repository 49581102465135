import React, { useState } from 'react';
import { StyledPasswordInput, StyledTextInput, Header } from './styles';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LinkOnboardModuleStore } from '../../LinkOnboardModuleStore';
import Layout from './Layout/Layout';
import hasValidationErrorForProperty from 'Helpers/hasValidationErrorForProperty';

const Credentials = () => {
  const intl = useIntl();
  const linkOnboardModuleStore = LinkOnboardModuleStore.Instance().Use();
  const [validationError, setValidationError] = useState(null);
  const isLoading = linkOnboardModuleStore.isloading;
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const onContinue = async (data) => {
    const validationError = await linkOnboardModuleStore.Validate(data.username);
    if (validationError && hasValidationErrorForProperty(validationError, 'UserName')) {
      setValidationError(validationError);
      return;
    }

    linkOnboardModuleStore.Enroll(data.username, data.password);
  };

  const userNameError = hasValidationErrorForProperty(validationError, 'UserName')
    ? intl.formatMessage({ id: 'account.log-in.username-taken' })
    : errors.username
    ? intl.formatMessage({ id: 'error.invalid-input' })
    : null;

  const isFormComplete = dirtyFields.username && dirtyFields.password;

  return (
    <Layout>
      <Header>{intl.formatMessage({ id: 'account.sign-up.create-account' })}</Header>
      <form onSubmit={handleSubmit(onContinue)}>
        <Controller
          name="username"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <StyledTextInput
              {...field}
              id="usernameInput"
              label={intl.formatMessage({ id: 'account.sign-up.create-username' })}
              errorMessage={userNameError}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ minLength: { value: 6, message: intl.formatMessage({ id: 'error.min-characters' }, { min: 6 }) } }}
          render={({ field }) => (
            <StyledPasswordInput
              {...field}
              id="passwordInput"
              label={intl.formatMessage({ id: 'account.sign-up.create-password' })}
              errorMessage={errors.password?.message}
            />
          )}
        />
        <Button fullWidth loading={isLoading} disabled={!isFormComplete} type="submit">
          {intl.formatMessage({ id: 'common.continue' })}
        </Button>
      </form>
    </Layout>
  );
};

export default Credentials;
