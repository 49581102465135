import { ApiService } from 'Core_Helpers/ApiService';
import { retryRequest } from 'Core_Helpers/retryRequest';

export class FoodBoxApiService {
  private static _instance: FoodBoxApiService = new FoodBoxApiService();
  private api: ApiService;
  constructor() {
    // Make sure we don't create more than one instance of this service
    if (FoodBoxApiService._instance) {
      return FoodBoxApiService._instance;
    }
    this.api = new ApiService();
  }

  public getFoodBoxProgress = async (userId, retries = 1) => {
    if (!userId) return;

    const { response, hasError } = await retryRequest(() => this.api.get(`/v1/FoodBox/status/${userId}`, {}), retries);

    return { response, hasError };
  };
}
