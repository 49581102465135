import React from 'react';
import {
  CardContainer,
  Title,
  BoxDescriptionContainer,
  BoxDescriptionItem,
  StartPathButton,
  StartPathButtonText,
} from './styles';
import { Typography } from '@mui/material';
import market from 'Assets/img/market.png';
import { LinkOnboardModuleStore } from '../../LinkOnboardModuleStore';

const Card3 = () => {
  const linkOnboardModuleStore = LinkOnboardModuleStore.Instance().Use();
  const handleStartPath = () => {
    linkOnboardModuleStore.CompleteOrientationCards();
  };

  return (
    <CardContainer>
      <Title sx={{ paddingTop: '8px' }}>What you get in return!</Title>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '16px', paddingBottom: '16px' }}>
        <img style={{ width: '60%', maxWidth: '320px' }} src={market} alt="Market" />
      </div>
      <Title sx={{ paddingTop: '8px' }}>Begin your Care Path & Get a Food Box Sent to You!</Title>
      <BoxDescriptionContainer>
        <Typography>What&apos;s in the box?</Typography>
        <ul>
          <BoxDescriptionItem>Citrus, Fruits</BoxDescriptionItem>
          <BoxDescriptionItem>Apples, Pears, 7 Bananas</BoxDescriptionItem>
          <BoxDescriptionItem>2 Loaves of Whole Grain Bread</BoxDescriptionItem>
          <BoxDescriptionItem>Various Root Vegetables</BoxDescriptionItem>
          <BoxDescriptionItem>Romaine Lettuce & Kale</BoxDescriptionItem>
        </ul>
        <Typography>What&apos;s not in the box?</Typography>
        <ul>
          <BoxDescriptionItem>Tree Nuts</BoxDescriptionItem>
          <BoxDescriptionItem>Dairy Products</BoxDescriptionItem>
        </ul>
      </BoxDescriptionContainer>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StartPathButton onClick={handleStartPath}>
          <StartPathButtonText>Start My Care Path</StartPathButtonText>
        </StartPathButton>
      </div>
    </CardContainer>
  );
};

export default Card3;
