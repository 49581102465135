import { getDefaultStore } from 'jotai';
import { experienceAtom } from '../atoms/experienceAtom';
import { default as CoreAuthService } from 'Core_Helpers/authentication/AuthService';
import { experiences } from '../models/experiences';

export class ExperienceStore {
  experience;
  atomStore;

  constructor() {
    this.atomStore = getDefaultStore();
    this.experience = this.atomStore.get(experienceAtom);
  }

  async updateExperience(experienceVal) {
    if (experienceVal === experiences.CORE) {
      const coreAuthService = await CoreAuthService.getInstance();
      await coreAuthService.tryRefreshTokens();
    }
    this.atomStore.set(experienceAtom, experienceVal);
  }

  getExperience() {
    return this.atomStore.get(experienceAtom);
  }
}
