import { Button, Typography } from '@mui/material';
import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 24px;
  padding-top: 32px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  background-image: ${(props) =>
    props.gradient ? 'linear-gradient(147deg, rgba(250, 177, 33, 1) 0%, rgba(251, 136, 20, 1) 100%)' : 'none'};
  height: 100%;
`;

export const CardContainer = styled.div`
  padding: 0 5%
  padding-top: 32px;
  position: relative;
  min-height: 75%;
  color: #292828;
`;

export const BoxDescriptionContainer = styled.div`
  padding: 8px 14px 8px 14px;
`;

export const BoxDescriptionItem = styled.li`
  font-size: 18px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: -0.18px;
  text-align: left;
  line-height: 25px;
`;

export const PyxHealthHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const Title = styled.div`
  color: rgba(41, 40, 40, 1);
  font-size: 24px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: -0.24px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Description = styled.div`
  color: rgba(41, 40, 40, 1);
  font-size: 24px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: -0.24px;
  text-align: center;
  padding-top: 24px;
`;

export const LearnMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #292828;
  font-size: 21px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: -0.21px;
  text-align: center;
  padding-top: 16px;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

export const StepsText = styled(Typography)`
  opacity: 1;
  color: rgba(41, 40, 40, 1);
  font-size: 20px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: -0.24px;
  text-align: left;
`;

export const StartPathButton = styled(Button)`
  border-radius: 8px;
  background-color: rgba(108, 11, 169, 1);
  color: #ffffff;
  margin-bottom: 16px;
`;

export const StartPathButtonText = styled(Typography)`
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 500;
  font-style: Medium;
  margin: 2px 8px 2px 8px;
  text-align: center;
`;

export const FooterImage = styled.img`
  position: absolute;
  bottom: -50px;
  left: 0;
`;
