import React, { useEffect } from 'react';
import './App.scss';
import Onboard from '../onboard/Onboard';
import Core from '../core/Core';
import { experiences } from './models/experiences';
import { useOnboardDispatch, useCoreDispatch } from 'Contexts/StoreContext';
import { AppExperienceService } from './services/AppExperienceService';
import FullScreenLoading from 'Core_Components/FullScreenLoading/FullScreenLoading';
import { ExperienceContext } from 'Contexts/ExperienceContext';
import { useAtom } from 'jotai';
import { experienceAtom } from './atoms/experienceAtom';
import { changeLanguage as onboardChangeLanguage } from 'Onboard_Redux/user';
import { changeLanguage as coreChangeLanguage } from 'Core_Redux/user';
import LinkOnboard from '../linkOnboard/LinkOnboard';
import branch from 'branch-sdk';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';

const AppRenderer = () => {
  const [experience, setExperience] = useAtom(experienceAtom);
  const onboardDispatch = useOnboardDispatch();
  const coreDispatch = useCoreDispatch();
  const experienceService = new AppExperienceService();

  const experienceUpdatedAsync = async () => {
    setExperience(experiences.LOADING);
    const currentExp = await experienceService.determineUpdatedExperienceAsync();
    //it has proven difficult to retrieve the dispatch method with the correct state outside of react, so pass dispatch here explicitly.
    await experienceService.handleUpdateEffectsAsync(currentExp, onboardDispatch, coreDispatch);
    setExperience(currentExp);
  };

  const updateLanguage = (language) => {
    coreDispatch(coreChangeLanguage({ language }));
    onboardDispatch(onboardChangeLanguage({ language }));
  };

  const renderExperience = () => {
    switch (experience) {
      case experiences.CORE:
        return <Core />;
      case experiences.ONBOARD:
        return <Onboard />;
      case experiences.LINK_ONBOARD:
        return <LinkOnboard />;
      case experiences.LOADING:
      default:
        return (
          <div style={{ display: 'flex', margin: 'auto' }}>
            <FullScreenLoading showMessage={true} />;
          </div>
        );
    }
  };

  useEffect(() => {
    // ----------- Branch - Web --------------
    branch.init(process.env.BRANCH_API_KEY, {}, function (err, data) {
      // Below is where we'll read branch data on the web in order to send the GroupId to the API
      // The web URL automatically routes to `/live-onboard`, so we don't need to change the pathname
      // The same way we do for Android/iOS
      // eslint-disable-next-line no-console
      console.log(err, data);
    });

    // ----------- Branch - CAPACITOR --- iOS & Android --------------
    BranchDeepLinks.addListener('init', (event) => {
      if (event.referringParams['+clicked_branch_link']) {
        // Below is where we'll read branch data on the web in order to send the GroupId to the API
        window.location.pathname = event.referringParams['$deeplink_path'];
      }
    });
  });

  //use context to distribute this function as to not introduce new state management dependencies two the different app experiences
  //this should be revisited when those experiences are refactored and should leverage any new technologies, i.e. jotai, in those refactors
  return (
    <ExperienceContext.Provider value={{ experienceUpdatedAsync, updateLanguage }}>
      {renderExperience()}
    </ExperienceContext.Provider>
  );
};

export default AppRenderer;
