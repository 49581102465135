import { UnauthenticatedApiService } from '../helpers/UnauthenticatedApiService';
import { retryRequest } from 'Core_Helpers/retryRequest';

export class OnboardApiService {
  private static _instance: OnboardApiService = new OnboardApiService();
  private api: UnauthenticatedApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (OnboardApiService._instance) {
      return OnboardApiService._instance;
    }
    this.api = new UnauthenticatedApiService();
  }

  public identifyMember = async (
    request: {
      firstName: string;
      lastName: string;
      birthDate: string;
      groupId: number;
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
      phoneNumber: string;
      email: string;
    },
    retries = 1,
  ) => {
    const { response, hasError } = await retryRequest(
      () => this.api.post('/v5/onboarding/identifymember', null, request),
      retries,
    );
    return { response, hasError };
  };

  public enroll = async (
    request: {
      ngmId: number;
      organizationId: number;
      firstName: string;
      lastName: string;
      phoneNumber: string;
      emailAddress: string;
      userName: string;
      password: string;
      timeZoneId: string;
    },
    retries = 1,
  ) => {
    const { response, hasError } = await retryRequest(
      () => this.api.post('/v5/onboarding/foodboxenrollment', null, request),
      retries,
    );
    return { response, hasError };
  };
}
