import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { browserHistory } from 'Core_Helpers/AppInsights';
import { CHECKINS } from 'Core_Pages/Routes/RoutesConfig';
import { UserResourcesStore } from 'Core_Services/userResources/UserResourcesStore';

const ACK_STORAGE_KEY = 'has_seen_foodbox_modal';

export class FoodBoxModalModuleStore extends Singleton implements IStateStore {
  private atomStore = getDefaultStore();
  private userResourcesStore = UserResourcesStore.Instance();

  private isOpenAtom;

  constructor() {
    super();
    this.isOpenAtom = atom(false);
  }

  public Use = () => {
    useAtom(this.isOpenAtom);

    return this;
  };

  public get IsOpen(): any {
    return this.atomStore.get(this.isOpenAtom);
  }

  public GoToScreeners() {
    this.atomStore.set(this.isOpenAtom, false);
    this.acknowledge();
    browserHistory.push(CHECKINS);
  }

  public open() {
    this.atomStore.set(this.isOpenAtom, true);
  }

  public acknowledge() {
    localStorage.setItem(ACK_STORAGE_KEY, 'true');
  }

  public readyForDisplay() {
    return this.userResourcesStore.HasFoodBoxEnrollmentResource && !this.hasAcknowledged();
  }

  private hasAcknowledged() {
    return JSON.parse(localStorage.getItem(ACK_STORAGE_KEY));
  }
}
