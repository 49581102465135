import React from 'react';
import PropTypes from 'prop-types';
import { NumberedCircleContainer } from './styles';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';

const NumberedCircle = ({ number }) => {
  return (
    <NumberedCircleContainer>
      <Avatar sx={{ backgroundColor: 'rgba(215, 109, 25, 1)', color: 'black' }}>
        <Typography fontWeight={700} fontSize={30}>
          {number}
        </Typography>
      </Avatar>
    </NumberedCircleContainer>
  );
};

NumberedCircle.propTypes = {
  number: PropTypes.number.isRequired,
};

export default NumberedCircle;
