import Credentials from './Credentials/Credentials';
import OrientationCards from './OrientationCards/OrientationCards';
import VerifyAddress from './VerifyAddress/VerifyAddress';
import WhoAreYou from './WhoYouAre/WhoYouAre';
import CannotVerify from './CannotVerify/CannotVerify';

export const pageConfig = {
  ORIENTATIONCARDS: { component: OrientationCards },
  WHOAREYOU: { component: WhoAreYou },
  VERIFYADDRESS: { component: VerifyAddress },
  CREDENTIALS: { component: Credentials },
  CANNOTVERIFY: { component: CannotVerify },
};
