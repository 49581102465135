import React from 'react';
import { CardContainer, Title, Description } from './styles';
import market from 'Assets/img/market.png';

const Card1 = () => {
  return (
    <CardContainer>
      <Title>Welcome and thank you for taking the time to learn more about us and our program</Title>
      <Description>At Pyx Health, we want to help you get the care you need when you need it.</Description>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
        <img style={{ width: '80%', maxWidth: '320px' }} src={market} alt="Market" />
      </div>
    </CardContainer>
  );
};

export default Card1;
