import styled from '@emotion/styled';
import { Dialog, Button } from '@mui/material';
import { breakpoints } from 'Styles/theme';

export const LeanMoreButton = styled(Button)`
  max-width: 100px;
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 550px;
    background-color: transparent;
    box-shadow: none;
  }
`;

export const ContentContainer = styled.div(
  ({ theme }) => `
    height: 100%;
    margin: 0 auto;
    padding: 20% 16px 15%;
    border-radius: 8px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    background-image: linear-gradient(180deg, rgba(243, 47, 179, 1) 0%, rgba(108, 11, 169, 1) 100%);

    align-items: center;
    text-align: center;

    ${theme.breakpoints.up(breakpoints.MOBILE)} {
        width: 65%;
    }
    ${theme.breakpoints.up(breakpoints.DESKTOP)} {
        width: 48%;
    }
    ${theme.breakpoints.down(breakpoints.TABLET)} {
        width: 90%;
    }
    `,
);
