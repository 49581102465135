import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  PyxHealthHeader,
  PageContainer,
  Title,
  ABitMoreInfoContainer,
  ABitMoreInfoText,
  ABitMoreInfoChatBoxContainer,
  FormContainer,
  StyledNextButton,
} from './styles';
import PyxHealthTransparentLogoWithText from 'Assets/img/PyxHealthTransparentLogoWithText.png';
import PyxHealthTransparentLogo from 'Assets/img/PyxHealthTransparentLogo.png';
import ChatRectangle from 'Assets/img/ChatRectangle.png';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { LinkOnboardModuleStore } from '../../LinkOnboardModuleStore';
import CallToGetSetUp from '../../components/CallToGetSetUp';
import { states } from '../../../shared/config/states';
import { PhoneInput, TextInput } from 'Stories/components/Input';
import { Controller, useForm } from 'react-hook-form';
import ZipCodeInput from 'Stories/components/Input/ZipCodeInput';
import hasValidationErrorForProperty from 'Helpers/hasValidationErrorForProperty';

const VerifyAddress = () => {
  const intl = useIntl();
  const linkOnboardModuleStore = LinkOnboardModuleStore.Instance().Use();
  const isLoading = linkOnboardModuleStore.IsLoading;
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();
  const [validationError, setValidationError] = useState(null);

  const isFormComplete =
    dirtyFields.addressLine1 &&
    dirtyFields.city &&
    dirtyFields.state &&
    dirtyFields.zip &&
    dirtyFields.phoneNumber &&
    !isLoading;

  const onContinue = async (data) => {
    const email = data.email !== '' ? data.email : null;
    const validationError = await linkOnboardModuleStore.Validate(null, email, data.phoneNumber);
    if (
      validationError &&
      (hasValidationErrorForProperty(validationError, 'PhoneNumber') ||
        hasValidationErrorForProperty(validationError, 'EmailAddress'))
    ) {
      setValidationError(validationError);
      return;
    }

    await linkOnboardModuleStore.IdentifyMember(
      data.addressLine1,
      data.addressLine2,
      data.city,
      data.state,
      data.zip,
      data.phoneNumber,
      email,
    );
  };

  const phoneNumberError =
    hasValidationErrorForProperty(validationError, 'PhoneNumber') || errors.phoneNumber
      ? intl.formatMessage({ id: 'error.invalid-input' })
      : null;

  const emailError = hasValidationErrorForProperty(validationError, 'EmailAddress')
    ? intl.formatMessage({ id: 'error.account-exists' })
    : errors.email
    ? intl.formatMessage({ id: 'error.invalid-input' })
    : null;

  return (
    <PageContainer>
      <PyxHealthHeader className="safe-area">
        <img src={PyxHealthTransparentLogoWithText} alt="Pyx Health Logo" />
      </PyxHealthHeader>
      <Title>Please verify your address</Title>
      <ABitMoreInfoContainer>
        <img src={PyxHealthTransparentLogo} alt="Pyx Health Logo" />
        <ABitMoreInfoChatBoxContainer>
          <img src={ChatRectangle} alt="Chat Rectangle" />
          <ABitMoreInfoText>We just need to verify your address and we can get started!</ABitMoreInfoText>
        </ABitMoreInfoChatBoxContainer>
      </ABitMoreInfoContainer>
      <form onSubmit={handleSubmit(onContinue)}>
        <FormContainer>
          <Typography>Address Line 1</Typography>
          <Controller
            name="addressLine1"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value:
                  /^(?!\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+)/,
                message: 'Address must not be a PO Box',
              },
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                id="addressLine1"
                inputProps={{
                  'aria-label': 'addressLine1',
                }}
                placeholder="e.g. 123 Main St"
                sx={{ width: '100%' }}
                variant="outlined"
                error={!!errors.addressLine1?.message}
                errorMessage={errors.addressLine1?.message}
              />
            )}
          />
          <Typography sx={{ marginTop: '12px' }}>Address Line 2</Typography>
          <Controller
            name="addressLine2"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value:
                  /^(?!\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+)/,
                message: 'Address must not be a PO Box',
              },
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                id="addressLine2"
                inputProps={{
                  'aria-label': 'addressLine2',
                }}
                placeholder="e.g. Apt 101"
                sx={{ width: '100%' }}
                variant="outlined"
                error={!!errors.addressLine2?.message}
                errorMessage={errors.addressLine2?.message}
              />
            )}
          />
          <Typography sx={{ marginTop: '12px' }}>City</Typography>
          <Controller
            name="city"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                id="city"
                inputProps={{
                  'aria-label': 'city',
                }}
                placeholder="e.g. San Francisco"
                sx={{ width: '100%' }}
                variant="outlined"
                error={!!errors.city?.message}
                errorMessage={errors.city?.message}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item mobile={6}>
              <Typography sx={{ marginTop: '12px' }}>State</Typography>
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="state"
                    inputProps={{
                      'aria-label': 'state',
                    }}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    error={!!errors.state?.message}
                    errorMessage={errors.state?.message}
                  >
                    {states.map((state) => (
                      <MenuItem id={'stateOption' + state} key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item mobile={6}>
              <Typography sx={{ marginTop: '12px' }}>Zipcode</Typography>
              <Controller
                name="zip"
                control={control}
                defaultValue=""
                rules={{
                  minLength: { value: 5, message: intl.formatMessage({ id: 'error.invalid-input' }, { min: 5 }) },
                }}
                render={({ field }) => (
                  <ZipCodeInput
                    {...field}
                    id="zip"
                    inputProps={{
                      'aria-label': 'zip',
                    }}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    error={!!errors.zip?.message}
                    errorMessage={errors.zip?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography sx={{ marginTop: '12px' }}>Phone Number</Typography>
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              minLength: { value: 10, message: intl.formatMessage({ id: 'error.invalid-phone' }, { min: 10 }) },
            }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                id="phoneNumber"
                inputProps={{
                  'aria-label': 'phoneNumber',
                }}
                placeholder="e.g. (415) 555-5555"
                sx={{ width: '100%' }}
                variant="outlined"
                errorMessage={phoneNumberError}
              />
            )}
          />
          <Typography sx={{ marginTop: '12px' }}>Email Address</Typography>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: intl.formatMessage({ id: 'error.invalid-input' }),
              },
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                id="email"
                inputProps={{
                  'aria-label': 'email',
                }}
                sx={{ width: '100%' }}
                variant="outlined"
                errorMessage={emailError}
              />
            )}
          />
          <StyledNextButton disabled={!isFormComplete} type="submit">
            Let&apos;s Go!
          </StyledNextButton>
        </FormContainer>
      </form>
      <CallToGetSetUp />
    </PageContainer>
  );
};

export default VerifyAddress;
