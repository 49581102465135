import React from 'react';
import { PyxHealthHeader, PageContainer, CardContainer, Title, Description } from './styles';
import ContactUs from 'Assets/img/contact-us.png';
import PyxHealthTransparentLogoWithText from 'Assets/img/PyxHealthTransparentLogoWithText.png';
import { Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const CannotVerify = () => {
  const intl = useIntl();
  return (
    <PageContainer>
      <PyxHealthHeader className="safe-area">
        <img src={PyxHealthTransparentLogoWithText} alt="Pyx Health Logo" />
      </PyxHealthHeader>
      <CardContainer>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5%' }}>
          <img style={{ width: '70%', maxWidth: '320px' }} src={ContactUs} alt="Contact Us!" />
        </div>
        <Title>Oh no, we cannot verify who you are with the app.</Title>
        <Description>We just need you to call us to verify your information!</Description>
        <Description sx={{ paddingBottom: '0px' }}>Please Call:</Description>
        <Typography variant="h4" sx={{ paddingBottom: '10px' }}>
          1 (800) 555-1212
        </Typography>
        <Button variant="outlined" href={`tel:+${process.env.ANDY_PHONE_NUMBER}`}>
          {intl.formatMessage({ id: 'common.call' })}
        </Button>
      </CardContainer>
    </PageContainer>
  );
};

export default CannotVerify;
