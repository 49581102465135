import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { CallContainer } from './styles';
import { useIntl } from 'react-intl';

const CallToGetSetUp = () => {
  const intl = useIntl();

  return (
    <CallContainer>
      <Typography fontSize={16}>You can also call to get set up!</Typography>
      <Grid container sx={{ marginTop: '16px' }} spacing={2}>
        <Grid item mobile={8}>
          <Typography fontSize={16}>Call 1 (800) 555-1212 or click the button to give us your info</Typography>
        </Grid>
        <Grid item mobile={4}>
          <Button variant="outlined" href={`tel:+${process.env.ANDY_PHONE_NUMBER}`}>
            {intl.formatMessage({ id: 'common.call' })}
          </Button>
        </Grid>
      </Grid>
    </CallContainer>
  );
};

export default CallToGetSetUp;
