import { UnauthenticatedIdentityApiService } from '../helpers/UnauthenticatedIdentityApiService';
import { retryRequest } from 'Core_Helpers/retryRequest';

export class IdentityApiService {
  private static _instance: IdentityApiService = new IdentityApiService();
  private api: UnauthenticatedIdentityApiService;

  constructor() {
    // Make sure we don't create more than one instance of this service
    if (IdentityApiService._instance) {
      return IdentityApiService._instance;
    }
    this.api = new UnauthenticatedIdentityApiService();
  }

  public getToken = async (
    request: {
      userName: string;
      password: string;
    },
    retries = 2,
  ) => {
    const body = {
      grant_type: 'password',
      client_id: 'pyx.pwa',
      username: request.userName,
      password: request.password,
    };
    const { response, hasError } = await retryRequest(
      () => this.api.post('/connect/token', null, body, { 'Content-Type': 'application/x-www-form-urlencoded' }),
      retries,
    );
    return { response, hasError };
  };

  public validate = async (
    request: {
      userName: string;
      emailAddress: string;
      phoneNumber: string;
    },
    retries = 2,
  ) => {
    const { response, hasError, error } = await retryRequest(
      () => this.api.post('api/v1/register/validate', null, request, null),
      retries,
    );
    return { response, hasError, error };
  };
}
