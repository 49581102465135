import React, { useState } from 'react';
import { Button, MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import theme from 'Styles/theme';
import { LearnMoreContainer, PageContainer, PyxHealthHeader } from './styles';
import PyxHealthTransparentLogoWithText from 'Assets/img/PyxHealthTransparentLogoWithText.png';
import Card1 from './Card1';
import Card2 from './Card2';
import Card3 from './Card3';
import EastIcon from '@mui/icons-material/East';

const OrientationCards = () => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const cards = [Card1, Card2, Card3];
  const CardComponent = cards[activeStep];

  const gradient = !activeStep == 0;

  return (
    <PageContainer gradient={gradient}>
      <PyxHealthHeader className="safe-area">
        <img src={PyxHealthTransparentLogoWithText} alt="Pyx Health Logo" />
      </PyxHealthHeader>
      <CardComponent />
      <LearnMoreContainer hidden={activeStep === 2}>
        Learn More
        <EastIcon sx={{ marginLeft: '8px' }} />
      </LearnMoreContainer>
      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        sx={{ backgroundColor: 'transparent' }}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep == 2}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep == 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </PageContainer>
  );
};

export default OrientationCards;
