import { Singleton } from 'Core_Helpers/Singleton';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { pageConfig } from './pages/pageConfig';
import { OnboardApiService } from './services/OnboardApiService';
import { IdentityApiService } from './services/IdentityApiService';
import StorageService, { TOKEN_RESPONSE_KEY } from 'Core_Helpers/authentication/StorageService';
import { ExperienceStore } from '../initialization/stores/experienceStore';
import { experiences } from '../initialization/models/experiences';

export class LinkOnboardModuleStore extends Singleton implements IStateStore {
  private onboardApiService: OnboardApiService;
  private identityApiService: IdentityApiService;
  private experienceStore: ExperienceStore;
  private atomStore = getDefaultStore();
  private firstName;
  private lastName;
  private dateOfBirth;
  private addressLine1;
  private addressLine2;
  private city;
  private state;
  private zip;
  private phoneNumber;
  private email;
  private username;
  private password;
  private currentPage;
  private isLoading;
  private groupId = 8402;
  private organizationId = 0;
  private networkGroupMemberId = 0;

  constructor() {
    super();
    this.onboardApiService = new OnboardApiService();
    this.identityApiService = new IdentityApiService();
    this.experienceStore = new ExperienceStore();
    this.firstName = atom(null);
    this.lastName = atom(null);
    this.dateOfBirth = atom('');
    this.addressLine1 = atom(null);
    this.addressLine2 = atom(null);
    this.city = atom(null);
    this.state = atom(null);
    this.zip = atom(null);
    this.phoneNumber = atom(null);
    this.email = atom(null);
    this.username = atom(null);
    this.password = atom(null);
    this.currentPage = atom(pageConfig.ORIENTATIONCARDS);
    this.isLoading = atom(false);
  }

  public Use = () => {
    useAtom(this.firstName);
    useAtom(this.lastName);
    useAtom(this.dateOfBirth);
    useAtom(this.addressLine1);
    useAtom(this.addressLine2);
    useAtom(this.city);
    useAtom(this.state);
    useAtom(this.zip);
    useAtom(this.phoneNumber);
    useAtom(this.email);
    useAtom(this.username);
    useAtom(this.password);
    useAtom(this.currentPage);
    useAtom(this.isLoading);
    return this;
  };

  public get FirstName() {
    return this.atomStore.get(this.firstName);
  }

  public setFirstName = (firstName: string) => {
    this.atomStore.set(this.firstName, firstName);
  };

  public get LastName() {
    return this.atomStore.get(this.lastName);
  }

  public setLastName = (lastName: string) => {
    this.atomStore.set(this.lastName, lastName);
  };

  public get DateOfBirth() {
    return this.atomStore.get(this.dateOfBirth);
  }

  public setDateOfBirth = (dateOfBirth: string) => {
    this.atomStore.set(this.dateOfBirth, dateOfBirth);
  };

  public get AddressLine1() {
    return this.atomStore.get(this.addressLine1);
  }

  public setAddressLine1 = (addressLine1: string) => {
    this.atomStore.set(this.addressLine1, addressLine1);
  };

  public get AddressLine2() {
    return this.atomStore.get(this.addressLine2);
  }

  public setAddressLine2 = (addressLine2: string) => {
    this.atomStore.set(this.addressLine2, addressLine2);
  };

  public get City() {
    return this.atomStore.get(this.city);
  }

  public setCity = (city: string) => {
    this.atomStore.set(this.city, city);
  };

  public get State() {
    return this.atomStore.get(this.state);
  }

  public setState = (state: string) => {
    this.atomStore.set(this.state, state);
  };

  public get Zip() {
    return this.atomStore.get(this.zip);
  }

  public setZip = (zip: string) => {
    this.atomStore.set(this.zip, zip);
  };

  public get PhoneNumber() {
    return this.atomStore.get(this.phoneNumber);
  }

  public setPhoneNumber = (phoneNumber: string) => {
    this.atomStore.set(this.phoneNumber, phoneNumber);
  };

  public get Email() {
    return this.atomStore.get(this.email);
  }

  public setEmail = (email: string) => {
    this.atomStore.set(this.email, email);
  };

  public get CurrentPage() {
    return this.atomStore.get(this.currentPage);
  }

  public get Username() {
    return this.atomStore.get(this.username);
  }

  public setUsername = (username: string) => {
    this.atomStore.set(this.username, username);
  };

  public get Password() {
    return this.atomStore.get(this.password);
  }

  public setPassword = (password: string) => {
    this.atomStore.set(this.password, password);
  };

  public setCurrentPage = (page) => {
    this.atomStore.set(this.currentPage, page);
  };

  public setIsLoading = (isLoading: boolean) => {
    this.atomStore.set(this.isLoading, isLoading);
  };

  public get IsLoading() {
    return this.atomStore.get(this.isLoading);
  }

  public CompleteOrientationCards = () => {
    this.setCurrentPage(pageConfig.WHOAREYOU);
  };

  public CompleteWhoAreYou = (firstName: string, lastName: string, dateOfBirth: string) => {
    this.setFirstName(firstName);
    this.setLastName(lastName);
    this.setDateOfBirth(dateOfBirth);
    this.setCurrentPage(pageConfig.VERIFYADDRESS);
  };

  public Validate = async (userName: string, email: string, phoneNumber: string) => {
    const { response, hasError, error } = await this.identityApiService.validate({
      userName: userName,
      emailAddress: email,
      phoneNumber: phoneNumber,
    });
    if (error) {
      this.setIsLoading(false);
      return error['errors'];
    }
  };

  public IdentifyMember = async (
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    phoneNumber: string,
    email: string,
  ) => {
    this.setIsLoading(true);

    this.setAddressLine1(addressLine1);
    this.setAddressLine2(addressLine2);
    this.setCity(city);
    this.setState(state);
    this.setZip(zip);
    this.setPhoneNumber(phoneNumber);
    this.setEmail(email);

    const { response, hasError } = await this.onboardApiService.identifyMember({
      firstName: this.atomStore.get(this.firstName),
      lastName: this.atomStore.get(this.lastName),
      birthDate: this.atomStore.get(this.dateOfBirth),
      groupId: this.groupId,
      address1: this.atomStore.get(this.addressLine1),
      address2: this.atomStore.get(this.addressLine2),
      city: this.atomStore.get(this.city),
      state: this.atomStore.get(this.state),
      zip: this.atomStore.get(this.zip),
      phoneNumber: this.atomStore.get(this.phoneNumber),
      email: this.atomStore.get(this.email),
    });

    if (hasError) {
      this.setCurrentPage(pageConfig.CANNOTVERIFY);
      this.setIsLoading(false);
      return;
    }

    this.networkGroupMemberId = response.networkGroupMemberId;
    this.organizationId = response.organizationId;
    this.setCurrentPage(pageConfig.CREDENTIALS);
    this.setIsLoading(false);
  };

  public Enroll = async (username: string, password: string) => {
    this.setIsLoading(true);
    this.setUsername(username);
    this.setPassword(password);
    const { response, hasError } = await this.onboardApiService.enroll({
      ngmId: this.networkGroupMemberId,
      organizationId: this.organizationId,
      firstName: this.atomStore.get(this.firstName),
      lastName: this.atomStore.get(this.lastName),
      phoneNumber: this.atomStore.get(this.phoneNumber),
      emailAddress: this.atomStore.get(this.email),
      userName: this.atomStore.get(this.username),
      password: this.atomStore.get(this.password),
      timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (hasError) {
      this.setCurrentPage(pageConfig.CANNOTVERIFY);
      this.setIsLoading(false);
      return;
    }

    const { response: loginResponse, hasError: hasLoginError } = await this.identityApiService.getToken({
      userName: this.atomStore.get(this.username),
      password: this.atomStore.get(this.password),
    });

    if (hasLoginError) {
      this.setCurrentPage(pageConfig.CANNOTVERIFY);
      this.setIsLoading(false);
      return;
    }

    await this.copyTokensToLocalStorage(loginResponse.access_token, loginResponse.refresh_token);

    await this.experienceStore.updateExperience(experiences.CORE);
    this.setIsLoading(false);
  };

  public copyTokensToLocalStorage = async (accessToken: string, refreshToken: string) => {
    const storage = await StorageService.getInstance();
    const value = JSON.stringify({ accessToken: accessToken, refreshToken: refreshToken });
    await storage.setItem(TOKEN_RESPONSE_KEY, value);
  };
}
