import React from 'react';
import {
  PyxHealthHeader,
  PageContainer,
  Title,
  ABitMoreInfoContainer,
  ABitMoreInfoText,
  ABitMoreInfoChatBoxContainer,
  FormContainer,
  StyledNextButton,
} from './styles';
import PyxHealthTransparentLogoWithText from 'Assets/img/PyxHealthTransparentLogoWithText.png';
import PyxHealthTransparentLogo from 'Assets/img/PyxHealthTransparentLogo.png';
import ChatRectangle from 'Assets/img/ChatRectangle.png';
import { Typography } from '@mui/material';
import { DateInput, TextInput } from 'Stories/components/Input';
import { LinkOnboardModuleStore } from '../../LinkOnboardModuleStore';
import CallToGetSetUp from '../../components/CallToGetSetUp';
import { Controller, useForm } from 'react-hook-form';
import { calculateAge, minBirthDate, minimumAgeRequirement } from 'Helpers/dates';
import { useIntl } from 'react-intl';

const WhoYouAre = () => {
  const intl = useIntl();
  const linkOnboardModuleStore = LinkOnboardModuleStore.Instance().Use();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const onContinue = (data) => {
    linkOnboardModuleStore.CompleteWhoAreYou(data.firstname, data.lastname, data.birthday);
  };

  const birthdayValidationMessage = (v) => {
    const enteredDate = new Date(v);
    if (calculateAge(enteredDate) < minimumAgeRequirement) {
      return intl.formatMessage({ id: 'error.age-requirement-not-met' });
    }
    if (enteredDate < minBirthDate || isNaN(enteredDate)) {
      return intl.formatMessage({ id: 'error.invalid-input' });
    } else {
      null;
    }
  };

  const isFormComplete = dirtyFields.firstname && dirtyFields.lastname && dirtyFields.birthday;

  return (
    <PageContainer>
      <PyxHealthHeader className="safe-area">
        <img src={PyxHealthTransparentLogoWithText} alt="Pyx Health Logo" />
      </PyxHealthHeader>
      <Title>Let us know who you are!</Title>
      <ABitMoreInfoContainer>
        <img src={PyxHealthTransparentLogo} alt="Pyx Health Logo" />
        <ABitMoreInfoChatBoxContainer>
          <img src={ChatRectangle} alt="Chat Rectangle" />
          <ABitMoreInfoText>One more bit of information after this!</ABitMoreInfoText>
        </ABitMoreInfoChatBoxContainer>
      </ABitMoreInfoContainer>
      <form onSubmit={handleSubmit(onContinue)}>
        <FormContainer>
          <Typography>First Name</Typography>
          <Controller
            name="firstname"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                {...field}
                sx={{ width: '100%' }}
                variant="outlined"
                id="firstname"
                inputProps={{
                  'aria-label': 'firstname',
                }}
                placeholder="e.g. Tom"
                errorMessage={errors.firstname?.message}
              />
            )}
          />
          <Typography sx={{ marginTop: '12px' }}>Last Name</Typography>
          <Controller
            name="lastname"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextInput
                {...field}
                sx={{ width: '100%' }}
                variant="outlined"
                id="lastname"
                inputProps={{
                  'aria-label': 'lastname',
                }}
                placeholder="e.g. Jones"
                errorMessage={errors.lastname?.message}
              />
            )}
          />
          <Typography sx={{ marginTop: '12px' }}>Date of Birth</Typography>
          <Controller
            name="birthday"
            control={control}
            defaultValue=""
            rules={{
              validate: (v) => birthdayValidationMessage(v),
            }}
            render={({ field }) => (
              <DateInput
                {...field}
                disableFuture
                error={!!errors.birthday?.message}
                errorMessage={errors.birthday?.message}
                slotProps={{ textField: { error: !!errors.birthday?.message, helperText: errors.birthday?.message } }}
                sx={{ width: '100%' }}
                id="birthday"
                inputProps={{
                  'aria-label': 'birthday',
                  placeholder: 'mm/dd/yyyy',
                }}
              />
            )}
          />
          <StyledNextButton type="submit" disabled={!isFormComplete}>
            Next
          </StyledNextButton>
        </FormContainer>
      </form>
      <CallToGetSetUp />
    </PageContainer>
  );
};

export default WhoYouAre;
