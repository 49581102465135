import { atom, getDefaultStore, useAtom } from 'jotai';
import { Singleton } from 'Core_Helpers/Singleton';
import { IStateStore } from 'Core_Helpers/IStateStore';
import { GetUserResourcesApiDataService } from './dataServices/GetUserResourcesApiDataService';
import AuthorizationService from 'Core_Helpers/authentication/AuthService';

export class UserResourcesStore extends Singleton implements IStateStore {
  private getUserResourcesApiDataService: GetUserResourcesApiDataService;
  private atomStore = getDefaultStore();
  private hasDirectMessageUserResourceAtom;
  private hasCallAgentUserResourceAtom;
  private hasInterventionEngineResourceAtom;
  private hasMyVideoToolsResourceAtom;
  private hasFoodBoxEnrollmentResourceAtom;

  constructor() {
    super();
    this.getUserResourcesApiDataService = new GetUserResourcesApiDataService();
    this.hasDirectMessageUserResourceAtom = atom(false);
    this.hasCallAgentUserResourceAtom = atom(false);
    this.hasInterventionEngineResourceAtom = atom(false);
    this.hasMyVideoToolsResourceAtom = atom(false);
    this.hasFoodBoxEnrollmentResourceAtom = atom(false);
  }

  public Use = () => {
    useAtom(this.hasDirectMessageUserResourceAtom);
    useAtom(this.hasCallAgentUserResourceAtom);
    useAtom(this.hasInterventionEngineResourceAtom);
    useAtom(this.hasMyVideoToolsResourceAtom);
    useAtom(this.hasFoodBoxEnrollmentResourceAtom);
    return this;
  };

  public get HasDirectMessageUserResource(): boolean {
    return this.atomStore.get(this.hasDirectMessageUserResourceAtom);
  }
  public get HasCallAgentUserResource(): boolean {
    return this.atomStore.get(this.hasCallAgentUserResourceAtom);
  }
  public get HasInterventionEngineResource(): boolean {
    return this.atomStore.get(this.hasInterventionEngineResourceAtom);
  }
  public get HasMyVideoToolsResource(): boolean {
    return this.atomStore.get(this.hasMyVideoToolsResourceAtom);
  }
  public get HasFoodBoxEnrollmentResource(): boolean {
    return this.atomStore.get(this.hasFoodBoxEnrollmentResourceAtom);
  }

  public initialize = async () => {
    const authService = await AuthorizationService.getInstance();
    const user = await authService.getUserInfo();

    if (!user.sub) return;

    const { response: userResources, hasError } = await this.getUserResourcesApiDataService.getUserResources(
      user.sub,
      3,
    );

    if (hasError) {
      return;
    }
    if (userResources?.resources) {
      this.atomStore.set(
        this.hasDirectMessageUserResourceAtom,
        userResources?.resources?.includes(UserResourceTypes.DIRECTMESSAGE),
      );
      this.atomStore.set(
        this.hasCallAgentUserResourceAtom,
        userResources?.resources?.includes(UserResourceTypes.CALLAGENT),
      );
      this.atomStore.set(
        this.hasInterventionEngineResourceAtom,
        userResources?.resources?.includes(UserResourceTypes.INTERVENTIONENGINE),
      );
      this.atomStore.set(
        this.hasMyVideoToolsResourceAtom,
        userResources?.resources?.includes(UserResourceTypes.MYVIDEOTOOLS),
      );
      this.atomStore.set(
        this.hasFoodBoxEnrollmentResourceAtom,
        userResources?.resources?.includes(UserResourceTypes.FOODBOXENROLLMENT),
      );
    } else {
      this.atomStore.set(this.hasDirectMessageUserResourceAtom, false);
      this.atomStore.set(this.hasCallAgentUserResourceAtom, false);
      this.atomStore.set(this.hasInterventionEngineResourceAtom, false);
      this.atomStore.set(this.hasMyVideoToolsResourceAtom, false);
      this.atomStore.set(this.hasFoodBoxEnrollmentResourceAtom, false);
    }
  };
}

export const UserResourceTypes = {
  CALLAGENT: 'Pyx.App.CallAgent',
  DIRECTMESSAGE: 'Pyx.App.DmAgent',
  INTERVENTIONENGINE: 'Pyx.App.InterventionEngine',
  MYVIDEOTOOLS: 'Pyx.App.MyVideoTools',
  FOODBOXENROLLMENT: 'Pyx.App.FoodboxEnrollment',
};
