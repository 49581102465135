import styled from 'styled-components';

export const CallContainer = styled.div`
  color: #292828;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: -0.18px;
  text-align: left;
  margin-top: 24px;
`;
