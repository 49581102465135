import styled from '@emotion/styled';
import { Paper } from '@mui/material';

export const FoodBoxTileContainer = styled(Paper)`
  border-radius: 8px;
  border: 1px solid rgba(216, 177, 116, 1);
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.13);
  background-color: rgba(251, 208, 155, 1);
  text-align: center;
  margin: 15px;
  padding: 15px;
`;
