import { experiences } from '../models/experiences';
import { fetch } from 'Core_Helpers/axiosAuthFetch';
import { submit } from 'Core_Helpers/axiosAuthSubmit';
import urlcat from 'urlcat';
import { default as CoreAuthService } from 'Core_Helpers/authentication/AuthService';
import { logout as onboardLogout } from 'Onboard_Redux/authentication';
import { logout as coreLogout } from 'Core_Redux/authentication';
import StorageService, { TOKEN_RESPONSE_KEY } from 'Core_Helpers/authentication/StorageService';
import { NotificationPromptStore } from '../stores/NotificationPromptStore';

const userInfoUrl = '/connect/userinfo';
const syncUserUrl = '/v5/members/:userId';

export class AppExperienceService {
  constructor() {}

  async retrieveInitExperienceAsync(user) {
    const storage = await StorageService.getInstance();
    const tokens = await storage.getItem(TOKEN_RESPONSE_KEY);
    if (tokens) {
      const parsedTokens = JSON.parse(tokens);

      if (parsedTokens?.refreshToken && parsedTokens?.accessToken && user?.activeProfileId) {
        return experiences.CORE;
      }
    }
    const location = window.location.pathname;
    if (location.includes('/link-onboard')) {
      return experiences.LINK_ONBOARD;
    }
    return experiences.ONBOARD;
  }

  async determineUpdatedExperienceAsync() {
    const storage = await StorageService.getInstance();
    const tokens = await storage.getItem(TOKEN_RESPONSE_KEY);

    if (tokens) {
      const parsedTokens = JSON.parse(tokens);
      if (parsedTokens?.refreshToken && parsedTokens?.accessToken) {
        return experiences.CORE;
      }
    }
    const location = window.location.pathname;
    if (location.includes('/link-onboard')) {
      return experiences.LINK_ONBOARD;
    }
    return experiences.ONBOARD;
  }

  async hydrateAndRetrieveInitialExperienceAsync() {
    const userInfoResult = await submit(urlcat(process.env.AUTHORITY, userInfoUrl));

    if (userInfoResult?.data?.sub) {
      try {
        const syncUserResult = await fetch(
          urlcat(process.env.API_BASE_URL, syncUserUrl, { userId: userInfoResult.data.sub }),
        );
        return await this.retrieveInitExperienceAsync(syncUserResult?.data);
      } catch {
        return experiences.ONBOARD;
      }
    }
    const location = window.location.pathname;
    if (location.includes('/link-onboard')) {
      return experiences.LINK_ONBOARD;
    }
    return experiences.ONBOARD;
  }

  async handleUpdateEffectsAsync(currentExp, onboardDispatch, coreDispatch) {
    if (currentExp === experiences.CORE) {
      const coreAuthService = await CoreAuthService.getInstance();
      await coreAuthService.tryRefreshTokens();
      await NotificationPromptStore.Instance().promptNativeNotificationAsync();
    } else {
      await onboardDispatch(onboardLogout());
      await coreDispatch(coreLogout());
    }
  }
}
